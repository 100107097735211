<template>
    <div>
        <b-card
        v-if="employeeStats"
        no-body
        class="card-company-table"
        
        >
        <h4 class="mb-1 mt-2 text-center">Performance Dashboard</h4>
        <b-table
            :items="employeeStats"
            responsive
            :fields="fields"
            class="mb-0"
        >
        <template #thead-top="data">
              <b-tr>
                  <b-th colspan="1"><span class="sr-only">Parameter</span></b-th>
                  <b-th variant="primary" class="text-center" colspan="2">Expected</b-th>
                  <b-th variant="secondary" class="text-center" colspan="2">Achieved</b-th>
              </b-tr>
        </template>
            <!-- company -->
            <template #cell(parameter)="data">
            <div class="d-flex align-items-center">
                <b-avatar
                class="mr-1"
                variant="light-primary"
            >
                <feather-icon
                size="18"
                :icon="data.item.avatar"
                />
            </b-avatar>
                <div class="font-weight-bolder">
                    {{ data.item.parameter }}
                </div>
            </div>
            </template>
    
            
            <!-- views -->
            <template #cell(expected_year)="data">
            <div class="d-flex flex-column">
                <span class="font-weight-bolder mb-25">{{ data.item.expected_year }}</span>
            </div>
            </template>
            <template #cell(expected_month)="data">
            <div class="d-flex flex-column">
                <span class="font-weight-bolder mb-25">{{ data.item.expected_month }}</span>
            </div>
            </template>
            <!-- views -->
            <template #cell(actual_year)="data">
            <div class="d-flex flex-column"  :class="data.item.totalsCSS">
                <span class="font-weight-bolder mb-25">{{ data.item.actual_year }} <span v-if="data.item.parameter === 'Average profiles Shared Daily'">(Total)</span></span>
            </div>
            </template>
            <template #cell(actual_month)="data">
            <div class="d-flex flex-column"  :class="data.item.averageCSS">
                <span class="font-weight-bolder mb-25">{{ data.item.actual_month }}</span>
            </div>
            </template>
            
            
        </b-table>
        </b-card>
    </div>
  </template>
  
  <script>
  import {
    BCard, BTable, BAvatar, BImg, BTr, BTh, BTd,
  } from 'bootstrap-vue'
  
  export default {
    components: {
      BCard,
      BTable, BTr, BTh, BTd,
      BAvatar,
      BImg,
    },
    props: {
      employeeStats: {
        type: Array,
        default: () => [],
      },
      attendance: {
        type: Number
      },
    },
  
    data() {
      return {
        fields: [
          { key: 'parameter', label: 'PARAMETER' },
          { key: 'expected_year', label: 'Yearly' },
          { key: 'expected_month', label: 'Monthly' },
          { key: 'actual_year', label: 'Yearly' },
          { key: 'actual_month', label: 'Monthly' },
        ],
      }
    },
    created() {
      //console.log('attendance: ', this.attendance)
    },
    // methods: {
    //   computeClass(type, data) {
    //     //console.log('in compute class: ',data)
    //     if(type === 'year' && data.parameter !== '') {
    //       const value = data.actual_year/data.expected_year
    //       //const value = (data.actual_year/this.attendance)
    //       console.log('in compute profile',data, type, value, 'profile shared')
    //       if(value > 0.5) {
    //         return 'text-success'
    //       } else if(value > 0.35 && value <= 0.5) {
    //         console.log(data, type, 'text-info')
    //         return 'text-info'
    //       } else if(value > 0.2 && value <= 0.35) {
    //         console.log(data, type, 'text-warning')
    //         return 'text-warning'
    //       } else {
    //         console.log(data, type, 'text-danger')
    //         return 'text-danger'
    //       }

    //     } else  {
    //       return 'text-info'
    //     }
    //   }
    // }
  }
  </script>
  
  <style lang="scss" scoped>
  @import '~@core/scss/base/bootstrap-extended/include';
  @import '~@core/scss/base/components/variables-dark';
  
  .card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
      background: $theme-dark-body-bg !important;
    }
  }
  .text-yellow {
    color: #ffc107 !important;
  }
  </style>
  