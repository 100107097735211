<template>
    <div>
        

        <b-card
        v-if="employeeInfo"
        no-body
        class="card-company-table mb-2"
        
        >
            <h4 class="mb-1 mt-1 text-center ">Employee Info</h4>
            <b-row>
                <b-col md="3" xl="3" lg="3" sm="6">
                    <span class="font-weight-bolder text-secondary mx-4"> Name: </span>
                </b-col>
                <b-col md="3" xl="3" lg="3" sm="6">
                    <span class="font-weight-bolder text-primary">{{ employeeInfo.name }}</span>
                </b-col>
                <b-col md="3" xl="3" lg="3" sm="6">
                    <span class="font-weight-bolder text-secondary mx-4"> Manager: </span>
                </b-col>
                <b-col md="3" xl="3" lg="3" sm="6">
                    <span class="font-weight-bolder text-primary">{{ employeeInfo.manager_name }}</span>
                </b-col>
                <b-col md="3" xl="3" lg="3" sm="6">
                    <span class="font-weight-bolder text-secondary mx-4"> DOJ: </span>
                </b-col>
                <b-col md="3" xl="3" lg="3" sm="6">
                    <span class="font-weight-bolder text-primary">{{ employeeInfo.doj }}</span>
                </b-col>
                <b-col md="3" xl="3" lg="3" sm="6">
                    <span class="font-weight-bolder text-secondary mx-4"> Tenure: </span>
                </b-col>
                <b-col md="3" xl="3" lg="3" sm="6">
                    <span class="font-weight-bolder text-primary">{{ employeeInfo.tenure_interval }}</span>
                </b-col>
                <b-col md="3" xl="3" lg="3" sm="6">
                    <span class="font-weight-bolder text-secondary mx-4"> Months Served: </span>
                </b-col>
                <b-col md="3" xl="3" lg="3" sm="6">
                    <span class="font-weight-bolder text-primary">{{ employeeInfo.months_served_last_year }}</span>
                </b-col>
                <b-col md="3" xl="3" lg="3" sm="6">
                    <span class="font-weight-bolder text-secondary mx-4"> Attendance: </span>
                </b-col>
                <b-col md="3" xl="3" lg="3" sm="6">
                    <span class="font-weight-bolder text-primary">{{ employeeInfo.attendance }}</span>
                </b-col>
                <b-col md="3" xl="3" lg="3" sm="6">
                    <span class="font-weight-bolder text-secondary mx-4"> Carry Forward: </span>
                </b-col>
                <b-col md="3" xl="3" lg="3" sm="6">
                    <span class="font-weight-bolder text-primary">{{ employeeInfo.carry_fwd_leaves }}</span>
                </b-col>
                <b-col md="3" xl="3" lg="3" sm="6">
                    <span class="font-weight-bolder text-secondary mx-4"> Comp Off: </span>
                </b-col>
                <b-col md="3" xl="3" lg="3" sm="6">
                    <span class="font-weight-bolder text-primary">{{ employeeInfo.comp_off_balance }}</span>
                </b-col>

            </b-row>
            <b-row>&nbsp;</b-row>
            
        </b-card>
    </div>
  </template>
  
  <script>
  import {
    BCard, BRow, BCol,
  } from 'bootstrap-vue'
  
  export default {
    components: {
      BCard, BRow, BCol,
    },
    props: {
        employeeInfo: {
            type: Object,
            default: () => {},
        },
    },
  
    data() {
      return {
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>

  </style>
  