<template>
    <div>
        

        <b-card
        v-if="employeeEvaluation"
        no-body
        class="card-company-table"
        
        >
            <h4 class="mb-1 mt-2 text-center">Evaluation Dashboard - Final Rating - <span class="text-primary"> {{ employeeEvaluation.rating }}</span></h4>
            <b-table
                :items="employeeEvaluation.evaluation"
                responsive
                :fields="fields"
                class="mb-0"
            >
                <!-- company -->
                <template #cell(category)="data">
                <div class="d-flex align-items-center">
                    <div class="font-weight-bolder">
                        {{ data.item.category }}
                    </div>
                </div>
                </template>
                <template #cell(question)="data">
                <div class="d-flex align-items-center">
                    <div class="font-weight-bolder">
                        {{ data.item.question }}
                    </div>
                </div>
                </template>
        
                <!-- views -->
                <template #cell(empRating)="data">
                <div class="d-flex flex-column">
                    <span class="font-weight-bolder mb-25">{{ data.item.empRating }}</span>
                </div>
                </template>
                <template #cell(teamRating)="data">
                <div class="d-flex flex-column">
                    <span class="font-weight-bolder mb-25">{{ data.item.teamRating }}</span>
                </div>
                </template>
                <template #cell(finalRating)="data">
                <div class="d-flex flex-column">
                    <span class="font-weight-bolder mb-25">{{ data.item.finalRating }}</span>
                </div>
                </template>
                
                
                
            </b-table>
        </b-card>
    </div>
  </template>
  
  <script>
  import {
    BCard, BTable, BAvatar,
  } from 'bootstrap-vue'
  
  export default {
    components: {
      BCard,
      BTable,
      BAvatar,
    },
    props: {
        employeeEvaluation: {
            type: Object,
            default: () => {},
        },
    },
  
    data() {
      return {
        fields: [
          { key: 'category', label: 'CATEGORY' },
          { key: 'Question', label: 'QUESTION' },
          { key: 'empRating', label: 'EMPLOYEE RATING' },
          { key: 'teamRating', label: 'TEADLEAD RATING' },
          { key: 'finalRating', label: 'FINAL RATING' },
        ],
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  @import '~@core/scss/base/bootstrap-extended/include';
  @import '~@core/scss/base/components/variables-dark';
  
  .card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
      background: $theme-dark-body-bg !important;
    }
  }
  </style>
  