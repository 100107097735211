<template>
    <!-- <v-select
        id="rating"
        label="text"
        placeholder="Select Rating"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        v-model="selectedRating"
        ref="rating"
        :options="ratingOptions"
        :reduce="(option) => option.text"
    /> -->
    
      <b-form-radio-group
        v-model="selectedRating"
        :options="ratingOptions"
        class="demo-inline-spacing"
        name="radio-inline"
        
      />
    
  </template>
  
  <script>
// import Ripple from 'vue-ripple-directive'
// import vSelect from "vue-select";
import { BFormGroup, BFormRadioGroup } from 'bootstrap-vue';
  export default {
    props: {
      text: String
    },
    data() {
      return {
        selectedRating: this.text,
        ratingOptions: [
          { text: 'Below Expectations', value: 2 },
          { text: 'Expectations Met', value: 3 },
          { text: 'Exceeds Expectations', value: 4 },
          { text: 'Outstanding', value: 5 }
        ]
      };
    },
    components: {
        // vSelect
        BFormGroup,
        BFormRadioGroup
    },
    watch: {
      selectedRating(newValue) {
        this.$emit('input', newValue);
      }
    },
//     directives: {
//     Ripple,
//   },
  };
  </script>
  <style lang="scss">
//   @import "@core/scss/vue/libs/vue-select.scss";
  </style>
  